.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  /* margin-top: 10vh; */
  /* background: #11111a; */
  font-family: "Poppins", sans-serif;
}
.wrapper .icon {
  position: relative;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.wrapper .icon i {
  color: #fff;
  transition: 0.4s linear;
}
.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  /* background-color: #111; */
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  /* opacity: @; */
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon::before,
.wrapper .icon::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.4s linear;
}
.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  transform: scale(0.8);
  transition: 0.4s linear;
}
.wrapper .icon:hover::before {
  border-radius: 50%;
  border-left: 4px solid;
  border-right: 4px solid;
  transform: skewX(20deg);
  color: #fff;
}
.wrapper .icon:hover::after {
  border-radius: 50%;
  border-top: 4px solid;
  border-bottom: 4px solid;
  transform: skewY(-20deg);
  color: #fff;
}
