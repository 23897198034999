.time-seconds {
    color: red;
    font-size: 23px;
}

.time-r {
    color: red
}

.time-y {
    color: yellow
}

.time-g {
    color: green
}