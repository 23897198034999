.focus {
  transition: transform 0.3s;
}

.image-style {
  background-color: #fff;
  padding: 5px;
  width: 3em;
}

.focus:hover {
  transform: scale(1.05);
}




.tech-skills {
  display: flex;
  flex-wrap: wrap;
}

h3,
h1 {
  text-align: left;
}