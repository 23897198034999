.experiance {
    padding: 25px 50px 50px 0;
    position: absolute;
    right: 5%;
    overscroll-behavior: contain;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
    width: 45%;

}

.experiance>h1 {
    color: #FFF;
    font-size: 2rem;
    font-weight: 500;
}