.about-experence-container {
  display: flex;
  padding: 50px 5%;

}

.about-container>div>h1 {
  color: #FFF;
  text-align: left;
  color: #FFF;
  font-size: 2rem;
  font-weight: 500;
}

.about-container {
  position: fixed;
  width: 35%;
  padding: 25px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.about-container>div:nth-child(1) {
  flex: 2;
}

.about-container>div:nth-child(2) {
  /* display: flex; */
  flex: 8
}

.personal-details {
  text-align: left;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  line-height: 1.25rem;
  padding: 0 100px;
  padding-left: 0;
}

.profile-image-container {
  display: flex;

  justify-content: center;
  align-items: center;

}

.profile-image-container>img {
  width: 30%;
  border: 1px solid grey;
}