.job-tech-stacks>span {
    border: 1px solid white;
    border-radius: 10px;
    padding: 0 .25rem;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    margin-top: .35rem;
    line-height: 1.625;
    margin-right: .5rem;
}

.job-tech-stacks>span:hover {
    border: 1px solid rosybrown;
}

.job-tech-stacks {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: .5rem;
}