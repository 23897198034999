#projects>.row>div {
    border: 1px solid rgb(35, 79, 211);
    margin-top: 50px;
    padding: 20px;
    box-shadow: -2px 3px 18px 7px rgba(62, 147, 173, 0.73);
        -webkit-box-shadow: -2px 3px 18px 7px rgba(62, 147, 173, 0.73);
        -moz-box-shadow: -2px 3px 18px 7px rgba(62, 147, 173, 0.73);}

#projects>.row>div :hover {
    cursor: pointer;
}