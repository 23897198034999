.experience-container {
    display: flex;
    color: #fff;
    margin: 50px 0;
    box-shadow: -2px 3px 18px 1px rgba(62, 147, 173, 0.73);
    -webkit-box-shadow: -2px 3px 18px 1px rgba(62, 147, 173, 0.73);
    -moz-box-shadow: -2px 3px 18px 1px rgba(62, 147, 173, 0.73);
    padding: 20px
}

.month-year {
    flex: 2;
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .75rem;
    line-height: 1rem;
    z-index: 10;
    color: #fff;
}

.experience-details {

    flex: 8;
    justify-content: flex-start !important;
    align-items: flex-start;
    margin-left: 1rem;

}

.experience-details>div {
    display: flex;
}

.experience-details>.list {
    /* border: 2px solid red */
}

.experience-details>.list>li {
    /* border: 2px solid green; */
    text-align: left;
}

.company-name {
    line-height: 1.25;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}

.job-position {
    margin-top: .05rem;

}

.job-description {
    text-align: left;
    font-size: .875rem;
    line-height: 1.5rem;
    margin-top: .5rem;
}